<template>
  <b-row>
    <b-col sm="12">
      <b-card>
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Pagos</h4>            
            <div class="small text-muted">Administración de todos los pagos ingresados al sistema</div>
          </b-col>
          
          <b-col sm="5">
            <b-col sm="12" class="d-none d-md-block" v-if="!table.mostrarFiltros">
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="table.mostrarFiltros=true" v-b-tooltip.hover title="Mostrar filtros">
                <i class="fa fa-filter"></i> Mostrar Filtros
              </b-button>
            </b-col>
            <b-col sm="12" class="d-none d-md-block" v-else>
              <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" v-if="table.mostrarFiltros" @click="table.mostrarFiltros=false" v-b-tooltip.hover title="Ocultar filtros">
                <i class="fa fa-window-close"></i>
              </b-button>
            </b-col>
          </b-col>                    
        </b-row>
      </b-card>

      <b-card id="list-payments" v-if="table.mostrarFiltros">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="10">
            <b-row>
              <b-col md="2" class="pr-0">
                <b-form-input type="date" size="md" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="2" class="pl-0">
                <b-form-input type="date" size="md" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>  
              <b-col sm="2">
                <v-select :options="arr.filters.points_sales" v-model="filters.points_sales" placeholder="Punto de Venta" :multiple="false" :select-on-tab="true"></v-select>
              </b-col>                                                                
              <b-col sm="3">
                <v-select :options="arr.filters.suppliers" v-model="filters.suppliers" placeholder="Proveedores" :multiple="true" :select-on-tab="true"></v-select>
              </b-col>  
              <b-col sm="2">
                <b-button variant="outline-dark" @click="filterPayments()" size="sm">
                  <b-icon icon="filter" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <b-col sm="2">
            <b-link>
              <export-excel
                  class = 'pull-right'
                  :data = "arr.export"
                  worksheet = "Listado de Pagos"
                  name = "list-payments.xls">
                  Exportar Datos
              </export-excel>            
            </b-link>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="8" class="pr-0" id="view_list_payments">
          <b-card>
            <b-row>
              <b-col>
                <b-form-group>
                  <b-input-group>
                    <b-form-input type="text" 
                                  placeholder="Ingrese su busqueda para filtrar la grilla" 
                                  v-model="table.filter"
                                  size="sm">
                    </b-form-input>              
                  </b-input-group>
                </b-form-group>            
              </b-col>
              <b-col>
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Pago">
                  <i class="fa fa-plus"></i> Agregar
                </b-button>                
              </b-col>
              <b-col sm="12">
                <b-table class="mb-0 table-payments-custom"
                        ref="table"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"  
                        :busy="table.isBusy"                      
                        v-if="table.items.length || table.isBusy">                       
                    
                    <template v-slot:table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"                    
                        :key="field.key"
                        :style="{ width: field.width }"
                      >
                    </template> 

                    <template v-slot:table-busy>
                      <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Cargando...</strong>
                      </div>
                    </template>

                    <template v-slot:cell(business)="data">
                      {{data.item.point_sale.business.reference}}
                    </template>

                    <template v-slot:cell(id)="data">
                      <div v-if="data.item.type_voucher && data.item.point_sale">                        
                        {{data.item.type_voucher.name}} <br>
                        {{data.item.point_sale.point_sale.toString().padStart(4,'0') + '-' + data.item.number.toString().padStart(8,'0')}}
                      </div>
                    </template>
                    
                    <template v-slot:cell(date)="data">
                      {{moment(data.item.date).format('DD/MM/YYYY')}}
                    </template>

                    <template v-slot:cell(staff_id)="data">                      
                      <span v-if="data.item.staff_id" v-b-tooltip.hover :title="'STAFF: ' + data.item.staff.name"> 
                        <b-avatar :src="data.item.staff.image"
                                  v-if="data.item.staff.image">
                        </b-avatar>
                        <b-avatar v-else                                                                         
                                  variant="dark">
                        </b-avatar>              
                      </span>               
                    </template>
                    
                    <template v-slot:cell(suppliers_id)="data">  
                      <div v-if="data.item.supplier">            
                        <b-avatar :src="data.item.supplier.image"
                                  v-if="data.item.supplier.image">
                        </b-avatar>
                        <b-avatar v-else 
                                  icon="building"                                            
                                  variant="dark">
                        </b-avatar>                                                     
                        &nbsp;<b>{{data.item.supplier.name}}</b>   
                        
                        <b-icon icon="circle-fill" v-if="!data.item.supplier.active" class="ml-2" variant="danger" v-b-tooltip.hover title="Proveedor INACTIVO" />                                       
                      </div>
                    </template>

                    <template v-slot:cell(observations)="data">
                      <div v-if="data.item.observations" v-b-tooltip.hover :title="data.item.observations">
                        <b-icon icon="chat-square-text"></b-icon>
                      </div>                                              
                    </template>

                    <template v-slot:cell(amount_total)="data">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.amount_total)}}
                    </template>

                    <template v-slot:cell(amount_pending)="data">
                      <div v-if="data.item.amount_pending>0">
                        {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.amount_pending)}}
                      </div>
                    </template>                    
                    
                    <template v-slot:cell(f_action)="data">
                      <b-dropdown right 
                                  text="Acción" 
                                  size="sm" 
                                  variant="outline-dark" 
                                  class="pull-right" 
                                  @show="setConfigTablePaymentsShow"
                                  @hide="setConfigTablePaymentsHide">
                        
                        <b-dropdown-item @click="sendPrinterSale(data.item)">
                          <b-icon icon="printer"></b-icon> Imprimir
                        </b-dropdown-item>                          
                        <b-dropdown-item @click="addImputation(data.item)" v-if="data.item.amount_pending>0">
                          <b-icon icon="paperclip"></b-icon> Imputar
                        </b-dropdown-item>                                                                          
                        <b-dropdown-header>Acciones</b-dropdown-header>
                        <b-dropdown-item @click="remove(data.item)" v-if="access.elements.deletePayments">
                          <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                        </b-dropdown-item>
                      </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col lg="4" id="contain_details_items">
          <div id="details_items">
            <b-card>            
              <b-row>              
                <b-col md="12">
                  
                  <!-- COMPROBANTE SELECCIONADO -->
                  <b-row class="mb-3">
                    <b-col md="8">
                      <div class="crud-payments-title" v-if="itemSelected">                        
                        <div class="crud-payments-title">
                          <span class="crud-payments-code" v-if="itemSelected.type_voucher && itemSelected.point_sale">                          
                            {{itemSelected.type_voucher.name}} {{itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + itemSelected.number.toString().padStart(8,'0')}}                          
                          </span><br>
                          <span v-if="itemSelected.supplier">
                            {{this.itemSelected.supplier.name}}                          
                          </span>                          
                        </div>
                      </div>
                    </b-col>
                    <b-col md="4">
                      <b-row>
                        <b-col md="12">
                          <b-button type="button" variant="outline-dark" size="sm" class="btn-pill float-right" @click="hideSub()" v-b-tooltip.hover title="Ocultar Detalle">
                            <i class="fa fa-window-close"></i>
                          </b-button>
                        </b-col>                      
                      </b-row>                   
                    </b-col>
                  </b-row>

                  <!-- DETALLE -->                
                  <b-row>
                    <b-col md="12" v-if="itemSelected">     
                      <b-link v-if="tableSubPayment.items.length || tableSubImputation.items.length" 
                              @click="openDetail()"
                              class="pull-right">
                        Ver detalle completo
                      </b-link>                                                           
                      <b-table  class="mb-0 table-payments-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubPayment.items"
                                :fields="tableSubPayment.fields"                            
                                v-if="tableSubPayment.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         

                        <template v-slot:cell(medio)="row"> 
                          <div v-if="row.item.type=='CASH'">
                            {{row.item.relation.cash.name}}            
                          </div>
                          <div v-if="row.item.type=='CHEQUE'">
                            CHEQUE
                          </div>
                        </template>

                        <template v-slot:cell(amount)="row">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(row.item.amount_total)}}
                        </template>

                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron medios de pagos</b-alert>

                      <hr>

                      <b-table  class="mt-3 table-payments-sub"
                                responsive
                                head-variant="dark"
                                :hover="true"
                                :small="true"
                                :fixed="true"
                                :items="tableSubImputation.items"
                                :fields="tableSubImputation.fields"                            
                                v-if="tableSubImputation.items.length">   
                        
                        <template v-slot:table-colgroup="scope">
                          <col
                            v-for="field in scope.fields"                    
                            :key="field.key"
                            :style="{ width: field.width }"
                          >
                        </template>                         

                        <template v-slot:cell(voucher)="row"> 
                          {{row.item.purchase.type_voucher.name + ' ' + row.item.purchase.point_sale.toString().padStart(4,'0') + '-' + row.item.purchase.number.toString().padStart(8,'0')}}
                        </template>

                        <template v-slot:cell(amount)="row">
                          {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(row.item.amount_total)}}
                        </template>

                        <template v-slot:cell(f_action)="row">
                          <b-link @click="saveDesimputar(row.item)">
                            <i class="fa fa-chain-broken"></i> Desimputar
                          </b-link>
                        </template>
                      </b-table>                      
                      <b-alert v-else variant="warning" show>No se encontraron imputaciones</b-alert>                      
                    </b-col>                    
                  </b-row>
                </b-col>
              </b-row>
            </b-card>           
          </div>
        </b-col>
      </b-row>    

      <b-row>
        <b-col lg="8" class="pr-0" id="view_footer_payments">  
          <b-card>
            <b-row>
              <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                  <i class="fa fa-angle-double-left"></i>
                  Volver
                </b-button>                        
              </b-col>

              <b-col>
                <nav v-if="table.tablePaginate">
                  <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="table.tablePaginate.total"
                                :per-page="table.tablePaginate.per_page"
                                v-model="table.currentPage"
                                @input="filterPayments()" />
                </nav>
              </b-col>          
            </b-row>
          </b-card>
        </b-col>
      </b-row>

      <!--
      ###########################
      ######### PRINTER #########
      ###########################
      -->
      <Printer v-if="crud.print.id" 
              :reference="crud.print.reference" 
              :registerID="crud.print.id"
              :key="crud.print.key"/>

      <!-- ########################### -->
      <!-- #####     MODALES     ##### -->
      <!-- ########################### -->

      <!-- CRUD MAIN -->
      <b-modal v-model="modal.form.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <form-wizard  title=""
                      subtitle=""
                      nextButtonText="Siguiente"
                      backButtonText="Atrás"
                      finishButtonText="Guardar"
                      color="#2f353a"
                      shape="tab"
                      @on-complete="save"
                      class="crud-payments-wizard">

          <tab-content title="General" icon="fa fa-edit" :before-change="paymentsValidGeneral">          
            <b-row>      
              <b-col md="4">
                <b-form-group label="Fecha">
                  <b-form-datepicker size="sm" v-model="crud.form.date" placeholder="Fecha" local="es"></b-form-datepicker>                      
                </b-form-group>            
              </b-col>
            </b-row>              
            <b-row> 
              <b-col md="5">
                <FindObject render="search"
                            type="suppliers" 
                            @select-object="loadSuppliers($event)" 
                            :valueID="crud.form.suppliers_id"
                            :where="conditionSuppliersActive"
                            :disabled="crud.form.id>0" />                 
              </b-col>    
              <b-col md="3">
                <FindObject render="search"
                            type="erpPointsSales" 
                            display="full_identification"
                            @select-object="loadPointsSales($event)" 
                            :valueID="crud.form.points_sales_id"
                            :key="crud.form.points_sales_id"
                            :disabled="crud.form.id>0"  />                 
              </b-col>
            </b-row>
            <b-row v-if="balanceCurrentAccount>0">
              <b-col>
                <b-alert show variant="success">
                  Se encontró saldo a favor: <b>({{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(balanceCurrentAccount)}})</b>
                </b-alert>
              </b-col>
            </b-row>            
          </tab-content>
          
          <tab-content title="Imputación" icon="fa fa-paperclip" :before-change="paymentsValidImputation">                      
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <b-table-simple hover small caption-top responsive v-if="arr.pending.length" class="crud-payments-table-items">
                  <b-thead head-variant="dark">                    
                    <b-tr>
                      <b-th width="10%" class="text-left">Imputar</b-th>
                      <b-th width="15%" class="text-left">Fecha</b-th>
                      <b-th width="40%" class="text-left">Comprobante</b-th>                      
                      <b-th width="10%" class="text-right">Total</b-th>
                      <b-th width="10%" class="text-right">Pendiente</b-th>
                      <b-th width="15%" class="text-center">A Imputar</b-th>                                            
                    </b-tr>
                  </b-thead>      
                  <b-tbody v-for="(item, index) in arr.pending" :key="item.id">                         
                    <b-tr :key="itemForceUpdate" v-if="crud.formPending[index]">
                      <b-td class="text-left align-middler">
                        <b-form-checkbox v-model="crud.formPending[index].check" 
                                          @change="validCheck(index)"
                                          switch 
                                          size="sm" 
                                          class="pull-left">
                        </b-form-checkbox> 
                      </b-td>
                      <b-td class="text-left align-middle">
                        {{moment(item.date).format('DD/MM/YYYY')}}
                      </b-td>
                      <b-td class="text-left align-middle">                        
                        {{item.type_voucher.name + ' ' + item.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')}}
                      </b-td>
                      <b-td class="text-right align-middle">
                        <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                        </div>
                        <div style="color:green;" v-else>
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                        </div>
                      </b-td>                      
                      <b-td class="text-right align-middle">
                        <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(calcVoucherPending(item))}}</b>
                        </div>
                        <div style="color:green;" v-else>
                          <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(calcVoucherPending(item))}}</b>
                        </div>                        
                      </b-td>                      
                      <b-td class="text-center align-middle">
                        <b-form-input type="number"
                                      size="sm"
                                      step="0.01"
                                      min="0"                                      
                                      @change="validAmount(index)"
                                      v-model="crud.formPending[index].amount"                                      
                                      placeholder="Ingresar monto a imputar"
                                      class="crud-payments-item-input"
                                      v-if="crud.formPending[index].check">
                        </b-form-input>                        
                      </b-td>                      
                    </b-tr>                    
                  </b-tbody>                              
                </b-table-simple>   
                <div v-else>
                  <b-alert show variant="warning">
                    No hay comprobante para imputar.
                  </b-alert>                
                </div>
              </b-col> 
            </b-row>            
            <b-row align-h="end" class="mb-2">
              <b-col lg="4" md="4" sm="4">
                <b-input-group size="sm" prepend="TOTAL A IMPUTAR">
                  <b-form-input v-model="calc.imputation" 
                                type="text"
                                :readonly="true"
                                class="payments-payments-add-total-final">
                  </b-form-input>   
                </b-input-group>                
              </b-col>
            </b-row>                        
          </tab-content>

          <tab-content title="Detalle" icon="fa fa-list" :before-change="paymentsValidPayments">
            <b-row align-h="center"> 
              <b-col lg="4">             
                <FinanceSUB @getFinances="storeFinances($event)" 
                            v-if="crud.form.suppliers_id && crud.form.points_sales_id"
                            :points_sales="crud.form.points_sales"
                            :amount_impute="calc.imputation"
                            :reset="resetControl"/>              
              </b-col>
            </b-row>
          </tab-content>

          <tab-content title="Asiento Contable" icon="fa fa-balance-scale" :before-change="paymentsValidCrudAccounting" v-if="showContabilidad"> 
            <div v-if="contabilidad.render" :key="'accounting_' + itemAccountingForceUpdate">
              <AccountingEntries  :typeDebe="contabilidad.typeDebe"
                                  :typeHaber="contabilidad.typeHaber"
                                  :defaultDebe="contabilidad.defaultDebe"
                                  :defaultHaber="contabilidad.defaultHaber"
                                  :reference="contabilidad.reference"
                                  :amountTotal="contabilidad.amountTotal"
                                  @getAsiento="getAsiento($event)" />         
            </div>
          </tab-content>          

          <tab-content title="Observaciones" icon="fa fa-flag-checkered">          
            <b-row>
              <b-col md="12">
                <b-form-group label="Observaciones">
                  <b-form-textarea                
                    v-model="crud.form.observations"
                    placeholder="Ingresar las observaciones..."
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>         
            </b-row>
            <b-row v-if="crud.form.payment">
              <b-col>
                <b-alert show variant="warning" v-if="parseFloat(crud.form.payment.total) > parseFloat(calc.imputation)">
                  El importe pagado es mayor al total de los comprobantes imputado.<br>
                  <b>Importe Imputado</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(this.calc.imputation)}}<br>
                  <b>Importe Pagado</b> {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(this.crud.form.payment.total)}}<br><br>
                  <i>El saldo quedará en cuenta corriente pendiente de imputación a favor de la empresa</i>
                </b-alert>
              </b-col>
            </b-row>             
          </tab-content>

        </form-wizard>
        
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancel()">Cancelar</b-button>              
        </div>                
      </b-modal>

      <!-- CRUD IMPUTACIONES -->
      <b-modal v-model="modal.imputation.active"
              no-close-on-esc
              no-close-on-backdrop
              hide-header-close      
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.imputation.title}}
        </div>
        
        <b-row align-h="end" class="mb-2">
          <b-col md="5">
            <FindObject render="search"
                        type="suppliers" 
                        @select-object="loadSuppliers($event)" 
                        :valueID="crud.form.suppliers_id"
                        :where="conditionSuppliersActive"
                        :disabled="crud.form.id>0" />                 
          </b-col>    
          <b-col md="3">
            <FindObject render="search"
                        type="erpPointsSales" 
                        display="full_identification"
                        @select-object="loadPointsSales($event)" 
                        :valueID="crud.form.points_sales_id"
                        :key="crud.form.points_sales_id"
                        :disabled="crud.form.id>0"  />                 
          </b-col>          
          <b-col lg="4" md="4" sm="4" v-if="crud.form.payment">
            <b-input-group size="sm" prepend="TOTAL PAGADO" class="payments-imputation-total-align">
              <b-form-input v-model="crud.form.payment.totalFormat" 
                            type="text"                                
                            :readonly="true"
                            class="payments-payments-add-total">
              </b-form-input>   
            </b-input-group>                
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <b-table-simple hover small caption-top responsive v-if="arr.pending.length" class="crud-payments-table-items">
              <b-thead head-variant="dark">                    
                <b-tr>
                  <b-th width="10%" class="text-left">Imputar</b-th>
                  <b-th width="15%" class="text-left">Fecha</b-th>
                  <b-th width="40%" class="text-left">Comprobante</b-th>                      
                  <b-th width="10%" class="text-right">Total</b-th>
                  <b-th width="10%" class="text-right">Pendiente</b-th>
                  <b-th width="15%" class="text-center">A Imputar</b-th>                                            
                </b-tr>
              </b-thead>      
              <b-tbody v-for="(item, index) in arr.pending" :key="item.id">                         
                <b-tr :key="itemForceUpdate" v-if="crud.formPending[index]">
                  <b-td class="text-left align-middler">
                    <b-form-checkbox v-model="crud.formPending[index].check" 
                                      @change="validCheck(index)"
                                      switch 
                                      size="sm" 
                                      class="pull-left">
                    </b-form-checkbox> 
                  </b-td>
                  <b-td class="text-left align-middle">
                    {{moment(item.date).format('DD/MM/YYYY')}}
                  </b-td>
                  <b-td class="text-left align-middle">                        
                    {{item.type_voucher.name + ' ' + item.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')}}
                  </b-td>
                  <b-td class="text-right align-middle">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(item.amount_total)}}</b>
                    </div>
                  </b-td>                      
                  <b-td class="text-right align-middle">
                    <div style="color:red;" v-if="item.type_voucher.type_balance == '-'">
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(calcVoucherPending(item))}}</b>
                    </div>
                    <div style="color:green;" v-else>
                      <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency: item.currency.code}).format(calcVoucherPending(item))}}</b>
                    </div>                        
                  </b-td>                      
                  <b-td class="text-center align-middle">
                    <b-form-input type="number"
                                  size="sm"
                                  step="0.01"
                                  min="0"                                      
                                  @change="validAmount(index)"
                                  v-model="crud.formPending[index].amount"                                      
                                  placeholder="Ingresar monto a imputar"
                                  class="crud-payments-item-input"
                                  v-if="crud.formPending[index].check">
                    </b-form-input>                        
                  </b-td>                      
                </b-tr>                    
              </b-tbody>                              
            </b-table-simple>   
            <div v-else>
              <b-alert show variant="warning">
                No hay comprobante para imputar.
              </b-alert>                
            </div>
          </b-col> 
        </b-row>            
        <b-row align-h="end" class="mb-2">
          <b-col lg="8" md="8" sm="8" v-if="calc.positiveBalance<0">
            <b-alert show variant="danger">
              Revise los comprobantes seleccionado, no puede imputar un monto mayor al importe total ingresado
            </b-alert>
          </b-col>
          <b-col lg="4" md="4" sm="4">
            <b-input-group size="sm" prepend="SALDO A FAVOR">
              <b-form-input v-model="calc.positiveBalanceFormat" 
                            type="text"
                            :readonly="true"
                            class="payments-payments-add-total-final">
              </b-form-input>   
            </b-input-group>                
          </b-col>
        </b-row>                        
          
        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="cancelImputation()">Cancelar</b-button>        
          <b-button variant="dark" @click="saveImputation()">Imputar</b-button>                
        </div>                
      </b-modal>

      <!-- CRUD DETAILS -->
      <b-modal v-model="modal.detail.active"
              header-bg-variant="dark"
              header-text-variant="white"
              size="xl">
        <div slot="modal-header">
          {{this.modal.detail.title}}
        </div>

        <b-row>
          <b-col sm="12">                     
            <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-dark">
              <b-tab title="General">
                <table class="table table-hover table-sm" v-if="itemSelected">
                  <tbody>
                    <tr>
                      <td class="crud-payments-title-table-custom align-middle">ID</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        <b>#{{this.itemSelected.id}}</b>
                      </td>
                    </tr>  
                    <tr>
                      <td class="crud-payments-title-table-custom align-middle">Empresa</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{this.itemSelected.point_sale.business.reference}}
                      </td>
                    </tr>                                                         
                    <tr v-if="itemSelected.type_voucher && itemSelected.point_sale">
                      <td class="crud-payments-title-table-custom align-middle">Comprobante</td>                      
                      <td class="crud-payments-value-table-custom align-middle">
                        <b>{{this.itemSelected.type_voucher.name + ' '  + this.itemSelected.point_sale.point_sale.toString().padStart(4,'0') + '-' + this.itemSelected.number.toString().padStart(8,'0')}}</b>
                      </td>
                    </tr>                                                           
                    <tr v-if="itemSelected.staff">
                      <td class="crud-payments-title-table-custom align-middle">Emisor</td>                      
                      <td class="crud-payments-value-table-custom align-middle">
                        <div v-if="itemSelected.staff">
                          {{itemSelected.staff.name}}
                        </div> 
                      </td>
                    </tr>                                                             
                    <tr>
                      <td class="crud-payments-title-table-custom align-middle">Fecha</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{moment(this.itemSelected.date).format('DD MMMM YYYY')}}
                      </td>
                    </tr>                                          
                    <tr v-if="itemSelected.point_sale">
                      <td class="crud-payments-title-table-custom align-middle">Punto de Venta</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{this.itemSelected.point_sale.point_sale}} - {{this.itemSelected.point_sale.name}}                          
                      </td>
                    </tr>                                           
                    <tr v-if="itemSelected.supplier">
                      <td class="crud-payments-title-table-custom align-middle">Proveedor</td>
                      <td class="crud-payments-value-table-custom align-middle">
                        {{this.itemSelected.supplier.name}}                        
                      </td>
                    </tr>                        
                    <tr v-if="itemSelected.amount_total">
                      <td class="crud-payments-title-table-custom align-middle">Total</td>
                      <td class="crud-payments-value-table-custom align-middle">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.getCurrencyCode}).format(this.itemSelected.amount_total)}}</b>                        
                      </td>
                    </tr>                         
                    <tr v-if="itemSelected.observations">
                      <td class="crud-payments-title-table-custom align-middle">Observaciones</td>
                      <td class="crud-payments-value-table-custom align-middle">                        
                        {{this.itemSelected.observations}}
                      </td>
                    </tr>                         
                    <tr v-if="itemSelected.amount_pending">
                      <td class="crud-payments-title-table-custom align-middle">Pendiente de Imputación</td>
                      <td class="crud-payments-value-table-custom align-middle table-warning">    
                        <b>{{Intl.NumberFormat('es-AR',{style:'currency',currency:this.getCurrencyCode}).format(this.itemSelected.amount_pending)}}</b>                        
                      </td>
                    </tr>                                                                      
                  </tbody>
                </table>
              </b-tab>              
              <b-tab title="Detalle de Pago">                
                <b-table class="mb-0 table-full-detail-payments"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailPayment.items"
                        :fields="tableDetailPayment.fields"
                        v-if="tableDetailPayment.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(medio)="row"> 
                    <div v-if="row.item.type=='CASH'">
                      {{row.item.relation.cash.name}}                        
                    </div>
                    <div v-if="row.item.type=='CHEQUE'">
                      CHEQUE
                    </div>
                  </template>

                  <template v-slot:cell(description)="row"> 
                    <div v-if="row.item.type=='CASH'">
                      <b>CAJA/CUENTA:</b> {{row.item.relation.cash.name}} |
                      <b>MONEDA:</b> {{row.item.relation.currency.name}}
                    </div>
                    <div v-if="row.item.type=='CHEQUE'">
                      <b>ID:</b> {{row.item.relation.id}} |
                      <b>NUMERO:</b> {{row.item.relation.number}} |
                      <b>BANCO:</b> {{row.item.relation.bank}} |
                      <b>EMISION:</b> {{moment(row.item.relation.date).format('DD/MM/YYYY')}} |
                      <b>VENCIMIENTO:</b> {{moment(row.item.relation.date_expiration).format('DD/MM/YYYY')}} |
                      <b-link :id="'more-data-cheque-' + row.index">Más Datos ...</b-link>

                      <b-popover :target="'more-data-cheque-' + row.index" triggers="hover" placements="auto">
                        <template #title>Datos Cheque</template>
                        <b>ID:</b> {{row.item.relation.id}} <br>
                        <b>NUMERO:</b> {{row.item.relation.number}} <br>
                        <b>BANCO:</b> {{row.item.relation.bank}} <br>
                        <b>EMISION:</b> {{moment(row.item.relation.date).format('DD/MM/YYYY')}} <br>
                        <b>VENCIMIENTO:</b> {{moment(row.item.relation.date_expiration).format('DD/MM/YYYY')}} <br>
                        <b>EMISOR:</b> {{row.item.relation.transmitter_name}} <br>
                        <b>CUIT:</b> {{row.item.relation.transmitter_documents_number}} <br>
                        <b>OBSERVACIONES:</b> {{row.item.relation.observations}}
                      </b-popover>                      
                    </div>
                  </template>                  

                  <template v-slot:cell(amount)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(row.item.amount_total)}}
                  </template>                  
                  
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>
              <b-tab title="Comprobantes Imputados">                
                <b-table class="mb-0 table-full-detail-payments"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableDetailImputation.items"
                        :fields="tableDetailImputation.fields"
                        v-if="tableDetailImputation.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>           

                  <template v-slot:cell(date)="row"> 
                    {{moment(row.item.purchase.date).format('DD/MM/YYYY')}}
                  </template>

                  <template v-slot:cell(voucher)="row"> 
                    {{row.item.purchase.type_voucher.name + ' ' + row.item.purchase.point_sale.toString().padStart(4,'0') + '-' + row.item.purchase.number.toString().padStart(8,'0')}}
                  </template>

                  <template v-slot:cell(amount_total)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(row.item.purchase.amount_total)}}
                  </template>               

                  <template v-slot:cell(amount_pending)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(calcPendingVoucher(row.item))}}
                  </template>                                 
                  
                  <template v-slot:cell(amount_impute)="row">
                    {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(row.item.amount_total)}}
                  </template>                                 
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>     
              <b-tab title="Asiento" v-if="showContabilidad">
                <b-table class="mb-0 table-full-detail-sales"
                        responsive
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="true"
                        :items="tableAccounting.items"
                        :fields="tableAccounting.fields"
                        v-if="tableAccounting.items.length">                       
                    
                  <template v-slot:table-colgroup="scope">
                    <col
                      v-for="field in scope.fields"                    
                      :key="field.key"
                      :style="{ width: field.width }"
                    >
                  </template>                                                                                     

                  <template v-slot:cell(code)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_code}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_code}}</span>
                    </div>
                  </template>  

                  <template v-slot:cell(name)="data">
                    <div v-if="data.item.type=='Debe'">
                      <span>{{data.item.accounting_accounts_name}}</span>
                    </div>
                    <div v-if="data.item.type=='Haber'">
                      <span class="ml-5">{{data.item.accounting_accounts_name}}</span>
                    </div>                                                          
                  </template>

                  <template v-slot:cell(debit)="data">
                    <span v-if="data.item.type == 'Debe'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.total)}}
                    </span>
                  </template>

                  <template v-slot:cell(credit)="data">
                    <span v-if="data.item.type == 'Haber'">
                      {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrencyCode}).format(data.item.total)}}
                    </span>
                  </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
              </b-tab>                                                
            </b-tabs>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.detail.active=false">Cancelar</b-button>                   
        </div>
      </b-modal>

    </b-col>
  </b-row>
</template>

<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import ErrorToken from '@/handler/errorToken'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import Param from '@/config/parameters'
  import FindObject from '@/components/inc/find/findObject'    
  import Printer from '@/components/inc/printer/printer'  
  import AccountingEntries from '@/components/inc/accounting/entries'  
  import moment from 'moment'
  import {FormWizard, TabContent} from 'vue-form-wizard'
  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import FinanceSUB from '@/components/inc/finances/sub'  

  export default {
    components: {
      FindObject,
      Printer,      
      FormWizard,
      TabContent,    
      FinanceSUB,  
      AccountingEntries,
    },
    data: () => {
      return {      
        access: {
          module_id: Modules.PAGOS_ERP,
          profile_id: Profiles.PERSONAL,
          view_reference: 'payments',
          elements: {
            deletePayments: true             
          }
        },
        table : {
          items: [],
          fields: [],
          filter: null,
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          isBusy: false,
          tablePaginate: null,          
        },     
        tableSubPayment : {
          items: [],
          fields: []
        },            
        tableSubImputation : {
          items: [],
          fields: []
        },                    
        tableDetailPayment : {
          items: [],
          fields: []
        },       
        tableAccounting : {
          items: [],
          fields: []
        },          
        tableDetailImputation : {
          items: [],
          fields: []
        },                    
        crud: {
          form: {
            id: 0,
            date: '',            
            types_vouchers_id: 0,
            types_vouchers: null,
            letter: '',
            number: 0,
            points_sales_id: 0,
            points_sales: null,              
            observations: '',            
            staff_id: 0,
            suppliers_id: 0,
            suppliers: null,  
            accountingEntry: null,  
            selectTypeVoucher: 'ordenpago',
            reference_voucher_id: 0,                                                           
            payment: null,
            imputation: [],    
            operation: 'add',
          },                        
          formPending: [],       
          print: {
            reference: '',
            id: 0,
            key: 0,
          }               
        },
        modal: {
          form: {
            active: false,
            title: '',
          },
          imputation: {
            active: false,
            title: '',
          },
          detail: {
            active: false, 
            title: '',
          },            
        },          
        arr: {
          payments: [],  
          pending: [],
          export: [],           
          filters : {
            suppliers: [],            
            points_sales: [],            
          },                              
        },           
        filters: {
          date_start: '',
          date_end: '',
          suppliers: null,
          points_sales: null,
        },     
        itemSelected: null,             
        itemForceUpdate: 0,
        resetControl: false,
        calc: {
          imputation: 0,
          positiveBalance: 0,
        },        
        itemAccountingForceUpdate: 0,    
        contabilidad: {
          render: false,
          typeDebe:"",                                 
          typeHaber:"",
          defaultDebe:[],
          defaultHaber:[],
          reference:'',
          amountTotal:0
        },      
        balanceCurrentAccount: 0,     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      this.access.elements.deletePayments = Helper.hasAccessElement(this.access, 'delete_payments')
      /* Fin configuracion */
    },     
    mounted() {   
      this.loadFieldTable()
      this.filterLoad()   
      this.filterPayments()              
      this.loadStyleConfig()
    },    
    computed: {      
      // CONDITIONS SELECT
      conditionSuppliersActive(){
        return [{field: 'active', condition: true}];
      },  

      // CURRENCY
      getCurrencySymbol() {
        return '$'
      },  
      getCurrencyCode() {
        return 'ARS'
      },  

      // IMPUTATION
      getImputation() {
        var imputation = 0
        if(this.crud.form.payment) {
          if(this.crud.formPending) {                        
            this.crud.formPending.forEach(element => {                             
              if(element.check) {    
                if(element.operation == '+') {
                  imputation = parseFloat(imputation) + parseFloat(element.amount)
                } else {
                  imputation = parseFloat(imputation) - parseFloat(element.amount)
                }
              }
            });   
          }
        }
        return imputation        
      },

      // CONFIGURACION
      showContabilidad() {
        var status = false
        var modules = Session.getSession().auth.user.permissions.modules        
        modules.forEach(element => {
          if(element.id == Modules.CONTABILIDAD) {                             
            status = true                       
          }
        })        
        return status
      },      

      // VOUCHER
      getRefVoucher() {        
        if(this.crud.form.types_vouchers && this.crud.form.points_sales && this.crud.form.number) {
          return this.crud.form.types_vouchers.name + ' '  + this.crud.form.points_sales.point_sale.toString().padStart(4,'0') + '-' + this.crud.form.number.toString().padStart(8,'0')  
        } else {
          return ''
        }      
      },       
    },    
    watch: {        
      'crud.form.suppliers_id': function (newQuestion, oldQuestion) {         
        if(this.crud.form.operation == 'add') {
          this.crud.form.payment = null           
          this.crud.form.imputation = []          
          this.resetControl = true

          this.arr.pending = []
          this.crud.formPending = []
          this.calc.imputation = 0
          this.balanceCurrentAccount = 0  
                    
          this.obtenerVouchersPendingImputarBySuppliers()
          this.searchVoucherBySupplier()
        }
      },
      'crud.form.points_sales_id': function (newQuestion, oldQuestion) {                
        if(this.crud.form.operation == 'add') {
          this.crud.form.payment = null           
          this.crud.form.imputation = []          
          this.resetControl = true

          this.arr.pending = []
          this.crud.formPending = []
          this.calc.imputation = 0
          this.balanceCurrentAccount = 0  
                    
          this.obtenerVouchersPendingImputarBySuppliers()        
          this.searchVoucherBySupplier()
        }
      },
    },    
    methods: {
      // CONFIGURACION
      loadStyleConfig () {        
        var view_list_payments = document.getElementById('view_list_payments')
        var view_footer_payments = document.getElementById('view_footer_payments')

        var padre = document.getElementById('contain_details_items')
        var hijo = document.getElementById('details_items')  
            
        hijo.style.width = padre.clientWidth - 45 + 'px'

        if(window.innerWidth < 991) {
          hijo.style.width = '100%'
          hijo.style.position = "relative"
          hijo.style.right = 'auto'

          view_list_payments.classList.remove('pr-0')
          view_footer_payments.classList.remove('pr-0')
        } else {          
          view_list_payments.classList.add('pr-0')
          view_footer_payments.classList.add('pr-0')
        }        
      },
      loadFieldTable () {
        // TABLE COMPROBANTES
        this.table.fields.push({key: 'business', label: 'Empresa', class:"align-middle text-left", width:"60px"})
        this.table.fields.push({key: 'id', label: 'Nº', class:"align-middle text-center", width:"120px"})
        this.table.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"80px"})                
        this.table.fields.push({key: 'suppliers_id', label: 'Proveedores', class:"align-middle", width:"200px"})                      
        this.table.fields.push({key: 'staff_id', label: 'Staff', class:"align-middle text-center", width:"60px"})        
        this.table.fields.push({key: 'amount_total', label: 'Total', class:"align-middle text-right", width:"60px"})        
        this.table.fields.push({key: 'amount_pending', label: 'Imputar', class:"align-middle text-right", width:"60px"})        
        this.table.fields.push({key: 'observations', label: '', class:"align-middle text-center", width:"25px"})                        
        this.table.fields.push({key: 'f_action', label:'', class:"align-middle", width:"60px"})

        // TABLE DETALLE
        this.tableSubPayment.fields.push({key: 'medio', label: 'Medio', class:"align-middle text-left text-truncate", width:"45%"})
        this.tableSubPayment.fields.push({key: 'amount', label: 'Importe', class:"align-middle text-right", width:"30%"})
        this.tableSubPayment.fields.push({key: 'f_action', label:'', class:"align-middle text-right", width:"25%"})

        // TABLE IMPUTATION
        this.tableSubImputation.fields.push({key: 'voucher', label: 'Comprobante', class:"align-middle text-left text-truncate", width:"45%"})
        this.tableSubImputation.fields.push({key: 'amount', label: 'Importe', class:"align-middle text-right", width:"30%"})
        this.tableSubImputation.fields.push({key: 'f_action', label:'', class:"align-middle text-right", width:"25%"})

        // TABLE DETALLE COMPLETO        
        this.tableDetailPayment.fields.push({key: 'medio', label: 'Medio', class:"align-middle text-left text-truncate", width:"20%"})                
        this.tableDetailPayment.fields.push({key: 'description', label: 'Detalle', class:"align-middle text-left text-truncate", width:"60%"})                
        this.tableDetailPayment.fields.push({key: 'amount', label: 'Importe', class:"align-middle text-right", width:"20%"})        

        // TABLE ASIENTO
        this.tableAccounting.fields.push({key: 'code', label: 'Código', class:"align-middle", width:"150px"})                
        this.tableAccounting.fields.push({key: 'name', label: 'Nombre', class:"align-middle", width:"250px"})                
        this.tableAccounting.fields.push({key: 'debit', label: 'Debe', class:"align-middle", width:"150px"})                
        this.tableAccounting.fields.push({key: 'credit', label: 'Haber', class:"align-middle", width:"150px"})
        
        // TABLE DETALLE DE IMPUTATION
        this.tableDetailImputation.fields.push({key: 'date', label: 'Fecha', class:"align-middle text-center", width:"15%"})
        this.tableDetailImputation.fields.push({key: 'voucher', label: 'Comprobante', class:"align-middle text-left text-truncate", width:"40%"})
        this.tableDetailImputation.fields.push({key: 'amount_total', label: 'Importe Total', class:"align-middle text-right", width:"15%"})
        this.tableDetailImputation.fields.push({key: 'amount_pending', label: 'Importe Pendiente', class:"align-middle text-right", width:"15%"})
        this.tableDetailImputation.fields.push({key: 'amount_impute', label: 'Importe Imputado', class:"align-middle text-right", width:"15%"})                                              
      },      
      getRowCount(items) {
        return items.length
      },
      onRowSelected(item) {         
        this.arr.payments.forEach((element, index) => {
          this.table.items[index]._showDetails = false
          if(item.length){
            if(element.id == item[0].id) {  
              this.openSub(element)                             
            }
          }
        })
      },   
      setConfigTablePaymentsShow() {
        var arrTable = document.getElementsByClassName('table-payments-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {          
          arrTable[i].style.minHeight = '150px'
        }        
      },
      setConfigTablePaymentsHide() {
        var arrTable = document.getElementsByClassName('table-payments-custom')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  
      setConfigTableSubShow() {
        var arrTable = document.getElementsByClassName('table-payments-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = '210px'
        }        
      },
      setConfigTableSubHide() {
        var arrTable = document.getElementsByClassName('table-payments-sub')
        var i = 0
        for(i=0; i<arrTable.length; i++) {
          arrTable[i].style.minHeight = 'auto'
        }        
      },  

      // SELECT
      loadSuppliers(object) {        
        if(object){
          this.crud.form.suppliers = object
          this.crud.form.suppliers_id = object.id   
          
          if(object.data_fiscal) {
            if(object.data_fiscal.points_sales_id) {
              if(!this.crud.form.points_sales_id) {
                this.crud.form.points_sales = object.data_fiscal.points_sales
                this.crud.form.points_sales_id = object.data_fiscal.points_sales_id
              }
            }
          }

          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.suppliers = null
          this.crud.form.suppliers_id = 0

          this.balanceCurrentAccount = 0
        }
      }, 
      loadPointsSales(object) {                
        if(object){
          this.crud.form.points_sales = object
          this.crud.form.points_sales_id = object.id           
          
          this.getBalanceCurrentAccount()
        } else {
          this.crud.form.points_sales = null
          this.crud.form.points_sales_id = 0

          this.getBalanceCurrentAccount()
        }
      },
      prepareExport(data) {        
        this.arr.export = []
        data.forEach(element => {
            var businessID = 0
            var businessName = ""          
            var suppliersID = 0
            var suppliersCode = ""
            var suppliersName = ""
            var voucherReference = ""

            if(element.point_sale && element.point_sale.business) {
              businessID = element.point_sale.business.id            
              businessName = element.point_sale.business.reference
            }
            if(element.supplier) {
              suppliersID = element.supplier.id
              suppliersCode = element.supplier.code
              suppliersName = element.supplier.name            
            }
            if(element.type_voucher) {            
              voucherReference = element.type_voucher.name + ' ' + element.point_sale.toString().padStart(4,'0') + '-' + element.number.toString().padStart(8,'0')
            }         

            this.arr.export.push({
              id: element.id,
              businessID: businessID,
              businessName: businessName,              
              date: element.date,      
              voucherReference: voucherReference,        
              suppliersID: suppliersID,
              suppliersCode: suppliersCode,
              suppliersName: suppliersName,                                 
              total: element.amount_total.replace('.',','),            
              total_pending: element.amount_pending,            
              observations: element.observations,    
              payment_medio: '',
              payment_amount: 0,
              imputation_reference: '',
              imputation_amount: 0,
            })

            element.details_payments.forEach(element1 => {            
              this.arr.export.push({
                payment_medio: element1.type,
                payment_amount: element1.amount_total.replace('.',','),
              })
            })

            element.details_imputations.forEach(element1 => {   
              var voucherReferenceImputation = ''
              if(element1.purchase.type_voucher) {            
                voucherReferenceImputation = element1.purchase.type_voucher.name + ' ' + element1.purchase.point_sale.toString().padStart(4,'0') + '-' + element1.purchase.number.toString().padStart(8,'0')
              }         

              this.arr.export.push({
                imputation_reference: voucherReferenceImputation,
                imputation_amount: element1.amount_total.replace('.',','),
              })
            })            
          });
      },

     // AMOUNT CURRENT ACCOUNT
      getBalanceCurrentAccount() {
        var result = serviceAPI.filtrarCurrentsAccountsBalance({
          suppliers_id: this.crud.form.suppliers_id,
          points_sales_id: this.crud.form.points_sales_id, 
        })

        result.then((response) => {
          var data = response.data
          this.balanceCurrentAccount = data
        })
      },

      // ABM MAIN
      add() {
        this.crud.form.operation = 'add'

        this.crud.form.id = 0           
        this.crud.form.date = moment().format('YYYY-MM-DD')        
        this.crud.form.observations = ''
        this.crud.form.staff_id = Helper.getStaffId()
        this.crud.form.suppliers_id = 0
        this.crud.form.suppliers = null   
        this.crud.form.points_sales_id = 0
        this.crud.form.points_sales = null        
        this.crud.form.types_vouchers_id = 0
        this.crud.form.types_vouchers = null
        this.crud.form.letter = ''
        this.crud.form.number = 0
        this.crud.form.payment = null
        this.crud.form.imputation = []         

        this.crud.formPending = []
        this.arr.pending = []

        this.calc.imputation = 0 
        this.balanceCurrentAccount = 0       

        this.modal.form.title = "Nuevo Pago"
        this.modal.form.active = true
      },            
      remove(item) {
        this.crud.form.id = item.id
        
        var voucher = "ID #" + item.id
        if(item.type_voucher && item.point_sale) {
          voucher = item.type_voucher.name + ' ' + item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
        }        

        this.$bvModal.msgBoxConfirm('¿Desea borrar el Pago ' + voucher + '?', {
          title: 'Borrar Pago',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarPagos(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.filterPayments()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },      
      save() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la generación del Pago?', {
          title: 'Generar Pago',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
              
            var result = serviceAPI.agregarPagos(this.crud.form);

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.filterPayments()
              this.$awn.success("Pago generado con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancel() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar el Pago?', {
          title: 'Cancelar el Pago',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.form.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },

      // ABM IMPUTATION
      addImputation(item) {
        this.crud.form.operation = 'imputation'

        this.crud.form.id = item.id           
        this.crud.form.suppliers_id = item.suppliers_id
        this.crud.form.suppliers = item.suppliers
        this.crud.form.points_sales_id = item.points_sales_id
        this.crud.form.points_sales = item.points_sales                                                 

        this.crud.formPending = []
        this.arr.pending = []
        this.obtenerVouchersPendingImputarBySuppliers()

        this.crud.form.payment = {}
        this.crud.form.payment.total = item.amount_pending
        this.crud.form.payment.totalFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.crud.form.payment.total)

        this.calc.imputation = 0
        this.calc.positiveBalance = item.amount_pending         
        this.calc.positiveBalanceFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.calc.positiveBalance)             

        var reference = item.type_voucher.name + ' ' + item.point_sale.point_sale.toString().padStart(4,'0') + '-' + item.number.toString().padStart(8,'0')
        this.modal.imputation.title = "Imputación de comprobantes (" + reference + ")"
        this.modal.imputation.active = true
      },
      saveImputation() {
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la imputación de comprobantes?', {
          title: 'Generar Imputación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'dark',
          okTitle: 'GENERAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'dark',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            this.loadFormImpuation()
            var result = serviceAPI.imputarPagos(this.crud.form);

            result.then((response) => {
              this.modal.imputation.active = false
              loader.hide()
              this.filterPayments()
              this.$awn.success("Imputación generada con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })              
      },          
      cancelImputation() {
        this.$bvModal.msgBoxConfirm('¿Desea cancelar la imputación de Comprobantes?', {
          title: 'Cancelar Imputación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {        
            this.modal.imputation.active = false
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })   
      },      
      saveDesimputar(item) {
        var reference = item.purchase.type_voucher.name + ' ' + item.purchase.point_sale.toString().padStart(4,'0') + '-' + item.purchase.number.toString().padStart(8,'0')
        this.$bvModal.msgBoxConfirm('¿Desea continuar con la desimputación del comprobante ' + reference + '?', {
          title: 'Generar Imputación',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'DESIMPUTAR',
          cancelTitle: 'Cancelar',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {          
            let loader = this.$loading.show();
            
            this.loadFormImpuation()
            var result = serviceAPI.desimputarPagos({
              details_imputation_id: item.id
            });

            result.then((response) => {              
              loader.hide()
              this.filterPayments(true)              
              this.$awn.success("Desimputación realizada con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })      
      },

      // FINANCES
      storeFinances(values) {
        this.crud.form.payment = values           
        this.crud.form.payment.totalFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.crud.form.payment.total)
        
        this.calc.positiveBalance = this.crud.form.payment.total 
        this.calc.positiveBalanceFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.calc.positiveBalance)             

        this.resetControl = false
      },

      // VALIDATION STEP
      paymentsValidGeneral() {
        if(this.crud.form.suppliers_id && this.crud.form.points_sales_id) {                            
          return true;
        } else {
          if(!this.crud.form.suppliers_id) {
            this.$awn.alert("No se cargó el proveedor");
            return false;
          }
          if(!this.crud.form.points_sales_id) {
            this.$awn.alert("No se cargó el punto de venta");
            return false;
          }
        }        
      },    
      paymentsValidImputation() {    
        this.loadFormImpuation()
        return true;        
      },       
      paymentsValidPayments() {
        if(this.crud.form.payment) {
          if(this.crud.form.payment.total>0) {
            if(this.crud.form.payment.total >= this.calc.imputation ) {
              this.getContabilidad()
              return true
            } else {
              this.$awn.alert("El total del pago no puede ser menor al total imputado");
              return false;                        
            }
          } else {
            this.$awn.alert("No se cargó ningún método de pago");
            return false;          
          }
        } else {
          this.$awn.alert("No se cargó ningún método de pago");
          return false;                    
        }
      },   
      paymentsValidCrudAccounting() {        
        if(this.crud.form.accountingEntry) {
          return true
        } else {
          this.$awn.alert("Revisar el Asiento Contable");
          return false
        }
      },

      // IMPUTACION COMPROBANTES
      obtenerVouchersPendingImputarBySuppliers() {
        this.crud.formPending = []
        
        if(this.crud.form.suppliers_id && this.crud.form.points_sales_id) {
          var result = serviceAPI.filtrarPendienteImputar({
            suppliers_id:this.crud.form.suppliers_id, 
            points_sales_id: this.crud.form.points_sales_id,
            hide_note_credit: true,
          })        
          result.then((response) => {
            var data = response.data    
            
            this.arr.pending = data   
            this.arr.pending.forEach(element => {                        
              this.crud.formPending.push({              
                check: false,
                id: element.id,                            
                amount: parseFloat(element.amount_total) - parseFloat(element.amount_impute),
                maxAmount: parseFloat(element.amount_total) - parseFloat(element.amount_impute),   
                operation: element.type_voucher.type_balance,
              })
            });
          })                   
        }
      },      
      calcVoucherPending(item) {
        return parseFloat(item.amount_total) - parseFloat(item.amount_impute)
      },
      validCheck(index) {             
        if(this.crud.formPending[index].check) {          
          this.crud.formPending[index].amount = parseFloat(this.crud.formPending[index].maxAmount).toFixed(2)        
          this.forceUpdateItem()
        }

        this.validImputation(index)
        this.getAmountImputation()
      },
      validAmount(index){       
        if(this.crud.formPending[index].amount=='') {
          this.crud.formPending[index].amount = 0
          this.crud.formPending[index].check = false
        }

        if( parseFloat(this.crud.formPending[index].amount) > parseFloat(this.crud.formPending[index].maxAmount)) {          
          this.crud.formPending[index].amount = parseFloat(this.crud.formPending[index].maxAmount).toFixed(2)
          this.forceUpdateItem()
        }

        if( parseFloat(this.crud.formPending[index].amount) <= 0) {          
          this.crud.formPending[index].amount = parseFloat(this.crud.formPending[index].maxAmount).toFixed(2)          
          this.crud.formPending[index].check = false
          this.forceUpdateItem()
        }        

        this.validImputation(index)
        this.getAmountImputation()   
      },
      validImputation(index) {
        if(this.crud.form.payment) {
          if(this.crud.formPending) {            
            var imputation = this.getImputation

            var amount = 0
            var valid = parseFloat(this.crud.form.payment.total) - parseFloat(imputation)                        
            if( valid.toFixed(2) < 0) {
              amount = parseFloat(this.crud.formPending[index].amount) - (parseFloat(imputation) - parseFloat(this.crud.form.payment.total))
              this.crud.formPending[index].amount = parseFloat(amount.toFixed(2))
                          
              if(Math.abs(parseFloat(amount.toFixed(2))) <= 0) {
                this.crud.formPending[index].check = false
              }

              var positiveBalance = parseFloat(this.crud.form.payment.total) - parseFloat(this.getImputation)            
              this.calc.positiveBalance = parseFloat(positiveBalance.toFixed(2))
              this.calc.positiveBalanceFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.calc.positiveBalance)             
              
              this.forceUpdateItem()
            } else {
              amount = parseFloat(this.crud.form.payment.total) - parseFloat(imputation)              
              this.calc.positiveBalance = parseFloat(amount.toFixed(2))
              this.calc.positiveBalanceFormat = Intl.NumberFormat('es-AR',{style:'currency',currency: this.getCurrencyCode}).format(this.calc.positiveBalance)             

              this.forceUpdateItem()
            }
          }
        }        
      },      
      getAmountImputation() {        
        var imputation = 0
        if(this.crud.formPending) {          
          this.crud.formPending.forEach(element => {             
            if(element.check) {    
              if(element.operation == '+') {
                imputation = parseFloat(imputation) + parseFloat(element.amount)
              } else {
                imputation = parseFloat(imputation) - parseFloat(element.amount)
              }
            }
          });   
        }
        this.calc.imputation = imputation    
        this.resetControl = true   
      },
      loadFormImpuation() {
        this.crud.form.imputation = []        
        if(this.crud.formPending.length) {
          this.crud.formPending.forEach(element => {                  
            if(element.check && element.amount>0) {              
              this.crud.form.imputation.push({                
                'id': element.id,               
                'amount': element.amount,               
              })                            
            }
          });
        }        
      },
      forceUpdateItem() {
        this.itemForceUpdate = this.itemForceUpdate + 1
      },        

      // SELECT VOUCHER 
      searchVoucherBySupplier() {
        if(!this.crud.form.id) {
          if(this.crud.form.suppliers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherBySupplier({
              'suppliers_id': this.crud.form.suppliers_id,
              'reference': this.crud.form.selectTypeVoucher,
              'point_sale_id': this.crud.form.points_sales_id,
            });
            result.then((response) => {    
              this.crud.form.types_vouchers_id = response.data[0].id							
              this.searchVoucher()                          
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error));
            })   						           
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.modal.form.title = this.crud.form.types_vouchers.name
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.point_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
          }                   
        }
      },			
      searchVoucher() {
        if(!this.crud.form.id) {
          if(this.crud.form.suppliers_id && this.crud.form.points_sales_id ) {
            var result = serviceAPI.obtenerVoucherById({
              'points_sales_id': this.crud.form.points_sales_id,
              'types_vouchers_id' : this.crud.form.types_vouchers_id,
            });
            result.then((response) => {                   
              this.crud.form.types_vouchers = response.data.type_voucher
              this.crud.form.letter = response.data.type_voucher.letter                    
              this.crud.form.number = response.data.number
          
              if( this.crud.form.types_vouchers ) {              
                this.modal.form.title = this.crud.form.types_vouchers.name
                this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
                this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
              }                                
            })
          }
        } else {                                      
          if( this.crud.form.types_vouchers ) {                          
            this.modal.form.title = this.crud.form.types_vouchers.name          
            this.modal.form.title = this.modal.form.title + ' ' + this.crud.form.points_sales.point_sale.toString().padStart(4,'0')
            this.modal.form.title = this.modal.form.title + '-' + this.crud.form.number.toString().padStart(8,'0')
          }                   
        }
      },  

      // FILTER
      filterLoad() {   
        this.filterLoadSuppliers()          
        this.filterLoadPointSale()                
        this.filters.date_start = moment().subtract(30, 'days').format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterLoadSuppliers() {
        var result = serviceAPI.obtenerProveedores()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.suppliers = []
          data.forEach(element => {            
            if(element.code) {
              this.arr.filters.suppliers.push({ code: element.id, label: element.code + ' | ' + element.name })            
            } else {
              this.arr.filters.suppliers.push({ code: element.id, label: element.name })            
            }            
          });          
        })   
      },
      filterLoadPointSale() {
        var result = serviceAPI.obtenerPuntosVentas()        
        result.then((response) => {
          var data = response.data    

          this.arr.filters.points_sales = []
          data.forEach(element => {                            
            this.arr.filters.points_sales.push({ code: element.id, label: element.full_identification })            
          });          
        })   
      },       
      filterPayments(forceOpenSub=false) {
        this.table.isBusy = true
        var result = serviceAPI.filtrarPagos(this.filters, this.table.currentPage)        
        result.then((response) => {          
          var data = response.data
          this.table.tablePaginate = data

          this.table.items = data.data
          this.arr.payments = data.data
          this.prepareExport(data.data)
          
          this.table.items.forEach(element => {
            if(element.amount_pending>0) {
              element._rowVariant = 'warning'                          
            }
          })  

          this.table.items.forEach(element => {            
            if(forceOpenSub) {
              if(element.id == this.itemSelected.id) {
                this.openSub(element)
              }              
            }                
          });

          if(this.table.rowSelected!=null) {            
            this.table.items[this.table.rowSelected]._showDetails = true                   
          }         

          if(this.$refs.table) {            
            this.$refs.table.$forceUpdate()
          }          
            
          this.table.isBusy = false
        })           
        .catch(error => {            
          this.table.isBusy = false  
          this.$awn.alert(ErrorToken.valid(error));
        })  
      },    

      // COMPROBANTE DETAIL
      openSub(item) {                
        this.itemSelected = item        
   
        this.tableSubPayment.items = item.details_payments
        this.tableSubImputation.items = item.details_imputations
        this.loadStyleConfig()
      },    
      hideSub() {
        this.tableSubPayment.items=[]
        this.tableSubImputation.items=[]
        this.itemSelected = null
      },               
      openDetail() {              
        this.tableDetailPayment.items = this.itemSelected.details_payments
        this.tableDetailImputation.items = this.itemSelected.details_imputations

        if(this.itemSelected.accounting_entries){
          if(this.itemSelected.accounting_entries.details){
            this.tableAccounting.items = this.itemSelected.accounting_entries.details
          } else {
            this.tableAccounting.items = []
          }
        } else {
          this.tableAccounting.items = []
        }

        this.modal.detail.title = "Detalle del Pago"
        this.modal.detail.active = true
      },       
      calcPendingVoucher(item) {
        return parseFloat(item.purchase.amount_total) - parseFloat(item.purchase.amount_impute)
      },      

      // PRINT
      sendPrinterSale(item) {        
        this.crud.print.key=Math.random()
        this.crud.print.id=item.id
        this.crud.print.reference = item.reportDefault
      },    
      
      /****************
       * CONTABILIDAD *
       * **************/      
      getContabilidad() {          
        this.contabilidad.render = false
        this.contabilidad.typeDebe = ""
        this.contabilidad.typeHaber = "",
        this.contabilidad.defaultDebe = []
        this.contabilidad.defaultHaber = []
        this.contabilidad.reference = this.getRefVoucher
        this.contabilidad.amountTotal = parseFloat(this.crud.form.payment.total)
        
        // ASIENTO POR DEFECTO         
        this.contabilidad.defaultDebe = this.getContabilidadAsientoPago('debe')
        this.contabilidad.defaultHaber = this.getContabilidadAsientoPago('haber')        
        // END
        
        this.contabilidad.render = true
        this.forceUpdate()
      },

      getAsiento(object) {
        this.crud.form.accountingEntry = object
      },
      forceUpdate() {
        this.itemAccountingForceUpdate = this.itemAccountingForceUpdate + 1
      },

      getContabilidadAsientoPago(type) {   
        if(type == 'debe') {                  
          var arrDebe = [
            {
              account: this.crud.form.suppliers.accounting_account_default,
              amount: parseFloat(this.crud.form.payment.total),
              disabled: true,
            }                  
          ]

          return arrDebe
        }        

        if(type == 'haber') {        
          var arrHaber = []            

          if(this.crud.form.payment.cash.detail) {
            this.crud.form.payment.cash.detail.forEach(element => {
              arrHaber.push({
                account: element.cash.accounting_account_default,
                amount: parseFloat(element.total),
                disabled: true,
              })
            });            
          }

          if(this.crud.form.payment.cheques.detail) {
            this.crud.form.payment.cheques.detail.forEach(element => {
              arrHaber.push({
                account: element.accounting_account_default,
                amount: parseFloat(element.total),
                disabled: true,
              })
            });
          }

          return arrHaber
        }        
      },          
    }
  }
</script>
<style>
  .crud-payments-title {
    font-size: 15px;
    font-weight: 600;
  }
  .crud-payments-code {
    color: gray;
    font-size: 12px;
  }   
  #details_items {
    position: fixed;
    right: 15px;    
  }     
  .table-payments-sub {
    overflow: auto;
    max-height: 350px;
  }
  .crud-payments-title-table-custom {
    color: #fff;
    background-color: #2f353a;
    border-color: #40484f;
    font-weight: 700;
    padding-right: 10px;
    width: 30%;
    text-align: right
  }
  .crud-payments-value-table-custom {
    padding-left: 5px;
    white-space: pre-line;
  }  
  .table-full-detail-payments {
    overflow: auto;
    max-height: 350px;    
  }  
  .crud-payments-totales-items-header {
    background: var(--dark);
    color: #fff;
    font-size: 12px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;    
    text-align: right;
    font-weight: 700;
  }
  .crud-payments-counts-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: solid;
    border-width: 1px;    
  }  
  .crud-payments-totales-items {
    font-size: 12px;    
    text-align: right;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;    
    border-color: var(--dark);
    border-style: dotted;
    border-width: 1px;    
  }    
  .crud-payments-wizard .wizard-header {
    padding: 0px;
  }  
  .crud-payments-item-input {
    height: 25px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
  }
  .crud-payments-table-items {
    height: 400px;    
    overflow: auto;
  }
  .payments-payments-add-total {
    font-weight: 800 !important;
    font-size: 15px !important;    
    text-align: right;
  }
  .payments-payments-add-total-final {
    font-weight: 800 !important;
    font-size: 15px !important;    
    text-align: right;
  }  
  .payments-imputation-total-align {
    margin-top: 25px;
  }
</style>
<style scoped>
  .b-avatar {
    width: 25px;
    height: 25px;    
  }  
</style>